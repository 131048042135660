
export const firebaseConfig = {
    apiKey: "AIzaSyDJ5rkBH3TIOOS54GERldFqRl0QVCcJuso",
    authDomain: "clothes-care-dry-cleaning.firebaseapp.com",
    databaseURL: "https://clothes-care-dry-cleaning-default-rtdb.firebaseio.com",
    projectId: "clothes-care-dry-cleaning",
    storageBucket: "clothes-care-dry-cleaning.appspot.com",
    messagingSenderId: "389273133223",
    appId: "1:389273133223:web:790cb3167fb65834993b82"
  };
  